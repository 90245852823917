/**
 * @fileoverview jQuery
 * @link https://jquery.com/
 */
// ------------------------------------------------------------------------------ //
// Jquery
// ------------------------------------------------------------------------------ //


import jQuery from "jquery";

window.$ = window.jQuery = jQuery;




